import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getTimezone } from 'src/app/helper/utils';

import { Reserve } from 'src/app/models/reserve';

@Component({
  selector: 'app-modal-reservation-detail-medium',
  templateUrl: './modal-reservation.component.html',
  styleUrls: ['./modal-reservation.component.scss']
})
export class ModalReservationComponent implements OnInit {
  @Input() reservation: Reserve;
  @Input() reservationBadge = '';
  @Input() title: string;

  @Output() submitForm = new EventEmitter();

  timeZone = getTimezone();

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }
}
