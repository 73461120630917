import { trigger, style, animate, transition, state } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('10ms', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('500ms', style({ opacity: 0 }))
    ])
  ]
);

export const ringAnimation = trigger(
  'ringAnimation', [
    state('off', style({
      'border-color': '#00a700',
      'background-color': '#00a700'
    })),
    state('on', style({
      'border-color': '#ff0000',
      'background-color': '#ff0000'
    })),
    transition('off => on', animate('1500ms')),
    transition('on => off', animate('1500ms')),
  ]
);
