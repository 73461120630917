import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TripStatus } from 'src/app/models/tripStatus';
import { User } from 'src/app/models/user';
import { getTimezone } from 'src/app/helper/utils';
import { CatalogMediumStatuses } from '../../../helpers/catalog';

@Component({
  selector: 'app-change-trip-status',
  templateUrl: './change-trip-status.component.html',
  styleUrls: ['./change-trip-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeTripStatusComponent implements OnInit {
  timeZone = getTimezone();
  defaultSlice = 5;
  sliceTripStatusesTo = 5;
  showViewLess = false;
  catalogMediumStatuses = CatalogMediumStatuses

  @Input() user: User;
  @Input() tripStatuses: TripStatus[] = [];

  constructor() { }

  ngOnInit() {
  }
}
