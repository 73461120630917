export const MapLatitudeCenter = -32.9391913;

export const MapLongitudeCenter = -60.6633612;

export const MapZoom = 12;

export const TicketAssetsMaxFiles = 3;

export const PauseTime = 600;

export const IconNotification = './assets/img/smod-logo-collapsed-secondary.png';

export const DefaultRefreshMapTime = 30;

export const SecondaryColor = '#7B287B';

export const ConfigTimezoneByDefault = 'UTC';
