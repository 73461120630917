import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-field-ticket',
  templateUrl: './label-field-ticket.component.html',
  styleUrls: ['./label-field-ticket.component.sass']
})
export class LabelFieldTicketComponent {
  @Input() value = {
    children: [],
    primaryValue: ''
  };
}
