import { RouteInfo } from './sidebar.metadata';
import { CatalogProfiles } from '../../../helpers/catalog';

export const ROUTES: RouteInfo[] = [
    {
        path: '/dashboard', title: 'Dashboard', icon: 'fas fa-columns', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
      path: '', title: 'Reservas', icon: 'far fa-calendar-check', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
      submenu: [
        { path: '/reservation/dashboard', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    {
        path: '', title: 'Viajes', icon: 'fas fa-road', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/trip/dashboard', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/trip/additional-use', title: 'Usos Adicionales', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/trip/additional-charge', title: 'Cobros Adicionales', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/trip/geofencing-break', title: 'Geofencing', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '', title: 'Hubs', icon: 'fas fa-map-marker-alt', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/hub/dashboard', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/hub/incident', title: 'Incidencias', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/medium/dashboard', title: 'Unidades', icon: 'fas fa-bicycle', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
            { path: '/medium/dashboard', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/medium/incident', title: 'Incidencias', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '', title: 'Usuarios', icon: 'far fa-user', class: ' has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/user', title: 'Dashboard', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/user/subscription', title: 'Abonos de Usuarios', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/ticket', title: 'Ayuda al usuario', icon: 'fas fa-headset', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/sanction', title: 'Sanciones', icon: 'fas fa-gavel', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/statistic', title: 'Reportes', icon: 'fas fa-clipboard-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/workshop', title: 'Taller', icon: 'fas fa-wrench', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Mercado Pago', icon: 'far fa-money-bill-alt', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/payment/authorization', title: 'Cobro Seguro Turistas', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/payment', title: 'Transacciones', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '', title: 'Comunicación', icon: 'far fa-envelope', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/communication/email', title: 'Emails', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/communication/push', title: 'Push Notifications', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/communication/whatsapp', title: 'WhatsApp', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/faq-category/view', title: 'Preguntas Frecuentes', icon: 'fas fa-question-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '', title: 'Catálogos', icon: 'fas fa-align-left', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/administrator', title: 'Administradores', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/spot-type', title: 'Tipos de Spot', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/subscription', title: 'Tipos de Abono', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/level', title: 'Niveles', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            { path: '/reward', title: 'Retos', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            { path: '/franchise', title: 'Tipos de Franquicia', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/ticket-type', title: 'Tipos de Ticket', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/penalty', title: 'Tipos de Penalidad', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/geofencing', title: 'Zonas funcionamiento', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/hub', title: 'Hubs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/lock', title: 'Locks', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/medium', title: 'Unidades', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/bikeway', title: 'Ciclovías', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/workshop-reason', title: 'Razones para taller', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/replacement-part', title: 'Repuestos', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/free-use-date', title: 'Días de uso gratuito', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/faq-category', title: 'Categorías FAQ', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/faq', title: 'Preguntas Frecuentes', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ]
    },
    {
        path: '/operation-time/edit', title: 'Rango horario', icon: 'far fa-clock', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/setting/edit', profiles: [CatalogProfiles.superAdmin], title: 'Configuración', icon: 'fas fa-cogs', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
