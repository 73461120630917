import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TicketType } from 'src/app/models/ticket-type';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketTypeService } from '../../../core-admin/services/ticket-type/ticket-type.service';
import { getFormArray, getClassToCropImage } from '../../../helpers/utils-admin';
import { AssetTypeService } from '../../../core-admin/services/asset-type/asset-type.service';
import { AssetType } from 'src/app/models/asset-type';
import { UploadService } from '../../../core-admin/services/uploadFile/upload-file.service';
import { CatalogAssetTypesCodes, CatalogFileClass, CatalogImageClass, CatalogMimeTypes } from '../../../helpers/catalog';
import { TicketAssetsMaxFiles } from '../../../helpers/config';
import { Ticket } from 'src/app/models/ticket';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { TicketService } from '../../../core-admin/services/ticket/ticket.service';
import { User } from 'src/app/models/user';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-modal-form-ticket',
  templateUrl: './modal-form-ticket.component.html',
  styleUrls: ['./modal-form-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFormTicketComponent implements OnInit, OnDestroy {
  ticketForm: FormGroup;
  ticketTypes: TicketType[] = [];
  assetTypes: AssetType[] = [];
  currentUser: User;
  allowedMimeTypes: string;
  ticketAssetsMaxFiles = TicketAssetsMaxFiles;
  private routerSubscription: Subscription;

  @Input() idUser?: number;
  @Input() idTrip?: number;
  @Input() idMedium?: number;

  constructor(
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastService,
    private ticketService: TicketService,
    private ticketTypeService: TicketTypeService,
    private assetTypeService: AssetTypeService,
    private uploadService: UploadService
  ) {
    this.subscribeRouterEvent();
    this.ticketForm = this.fb.group({
      idTicketType: ['', Validators.required],
      ticketComment: this.fb.group({
        comment: ['', Validators.required]
      }),
      ticketAsset: this.fb.array([])
    });
  }

  ngOnInit() {
    this.getTicketTypes();
    this.getAssetTypes();
  }

  getTicketTypes() {
    this.ticketTypeService.get<TicketType>().subscribe(
      response => {
        this.ticketTypes = response.data['rows'];
        this.cdr.detectChanges();
      }
    );
  }

  getAssetTypes() {
    this.assetTypeService.get<AssetType>().subscribe(
      response => {
        this.assetTypes = response.data['rows'];
        this.getAllowedMimeTypes();
        this.cdr.detectChanges();
      }
    );
  }

  getAllowedMimeTypes() {
    const mimeTypes = [];
    const validMimeTypes = [CatalogAssetTypesCodes.pdf, CatalogAssetTypesCodes.img];
    this.assetTypes.forEach( assetType => {
      if (validMimeTypes.includes(assetType.code)) {
        mimeTypes.push(assetType.mimeTypes);
      }
    });
    this.allowedMimeTypes = mimeTypes.join(',');
  }

  subscribeRouterEvent() {
    this.routerSubscription = this.router.events.subscribe( _ => {
      this.activeModal.dismiss();
    });
  }

  onSubmit() {
    const formModel = this.ticketForm.value;
    const currentTicket = new Ticket({
      idTicketType: formModel.idTicketType,
      ticketComment: formModel.ticketComment,
      ticketAsset: formModel.ticketAsset,
      idUser: (this.idUser) ? this.idUser : null,
      idTrip: (this.idTrip) ? this.idTrip : null,
      idMedium: (this.idMedium) ? this.idMedium : null
    });
    this.ticketService.add<Ticket>(currentTicket).subscribe(response => {
      this.toastr.success('Ticket creado exitosamente!');
      this.activeModal.dismiss();
    });
  }

  fileChangeListener(event) {
    const file = event.target.files[0];
    const imageClass = {
      typeClass: CatalogImageClass.base,
      fileClass: CatalogImageClass.ticketAsset
    }
    const pdfClass = {
      typeClass: CatalogFileClass.documentClass,
      fileClass: CatalogFileClass.pdf
    }
    const fileClass = file.type === CatalogMimeTypes.pdf ? pdfClass : imageClass
    this.uploadService.uploadFiles(file, fileClass.typeClass, fileClass.fileClass, null).subscribe(
      response => {
        const index = this.assetTypes.findIndex( item => (item.mimeTypes.split(',')).indexOf(file.type) > -1 );
        if (index > -1) {
          this.setTicketAssets({
            url: response.data['url'],
            urlSigned: response.data['urlSigned'],
            codeAssetType: this.assetTypes[index].code
          });
        }
      }
    );
  }

  setTicketAssets(asset) {
    if (asset) {
      let urlSigned: Array<string> = [(asset.urlSigned) ? asset.urlSigned : asset.url];
      if (asset.codeAssetType === CatalogAssetTypesCodes.pdf || asset.codeAssetType === CatalogAssetTypesCodes.pdfFile) {
        urlSigned = ['./assets/img/pdf-icon.png'];
      }
      if (asset.codeAssetType === CatalogAssetTypesCodes.csv) {
        urlSigned = ['./assets/img/csv-icon.png'];
      }
      const ticketAsset = this.fb.group({
        url: [asset.url],
        urlSigned,
        codeAssetType: [asset.codeAssetType],
        class: [getClassToCropImage((asset.urlSigned) ? asset.urlSigned : asset.url)]
      });
      getFormArray('ticketAsset', this.ticketForm).push(ticketAsset);
      this.cdr.detectChanges();
    }
  }

  removeTicketAsset(index) {
    getFormArray('ticketAsset', this.ticketForm).removeAt(index);
    this.cdr.detectChanges();
  }

  getControls() {
    return getFormArray('ticketAsset', this.ticketForm).controls;
  }

  ngOnDestroy() {
    if (this.routerSubscription) { this.routerSubscription.unsubscribe(); }
  }
}
