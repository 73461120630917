export const EndpointsConstants = {
  additionalCharge: {
    base: '/additional-charge',
    log: '/additionalCharge',
    payment: '/payment'
  },
  additionalUse: {
    base: '/additional-use',
    log: '/additionalUse',
    reject: '/reject',
    additionalCharge: '/additional-charge',
    sanctionAndAdditionalCharge: '/sanction-charge',
    sanction: '/sanction'
  },
  admin: {
    base: '/admin',
    avatar: 'assets/admin-avatar',
    me: '/me'
  },
  assetType: {
    base: '/asset-type'
  },
  auth: {
    base: '/auth/admin',
    login: '/login',
    forgotPassword: '/passwordrecovery',
    resetPassword: '/passwordupdate'
  },
  bikeway: {
    base: '/bikeway'
  },
  communication: {
    base: '/communication',
    email: '/email',
    message: '/message',
    push: '/push'
  },
  dashboard: {
    base: '/dashboard',
    user: '/user',
    cards: '/cards',
    userdifferential: '/userdifferential',
    medium: '/medium',
    failures: '/failures',
    tickets: '/tickets',
    hubs: '/hubs',
    hub: '/hub',
    reserves: '/reserves'
  },
  faq: {
    base: '/faq'
  },
  faqCategory: {
    base: '/faq-category',
    log: '/faqCategory'
  },
  file: {
    base: '/upload',
    image: '/image',
    pdf: '/pdf',
    exportcsv: '/exportcsv',
    file: '/file'
  },
  franchise: {
    base: '/franchise'
  },
  freeUseDate: {
    base: '/free-use-date',
    log: '/freeUseDate'
  },
  geo: {
    base: '/geo',
    adminnearby: '/adminnearby'
  },
  geofencing: {
    base: '/geofencing',
  },
  geofencingBreak: {
    base: '/geofencing-break',
  },
  hub: {
    base: '/hub',
    alarm: '/alarm'
  },
  hubIncident: {
    base: '/hub-incident'
  },
  level: {
    base: '/level',
    log: '/level'
  },
  lock: {
    base: '/lock',
    batteriesLevel: '/batteries-level',
    free: '/free',
    statuses: '/statuses',
    command: '/command',
    ring: '/ring',
    shutdown: '/shutdown',
    offline: '/offline',
    position: '/position',
    bulkCreateByFile: '/bulk-create-by-file'
  },
  log: {
    base: '/log'
  },
  medium: {
    base: '/medium',
    position: '/position',
    reserve: '/reserve',
    statuses: '/statuses',
    trips: '/trips',
    unlock: '/unlock'
  },
  mediumType: {
    base: '/mediumType'
  },
  notification: {
    base: '/notification',
    markAll: '/markall',
    markOne: '/markone'
  },
  operationTime: {
    base: '/operation-time',
    log: '/operationTime'
  },
  payment: {
    base: '/payment',
    authorization: '/authorization',
    cancel: '/cancel',
    pay: '/pay'
  },
  penalty: {
    base: '/penalty'
  },
  profile: {
    base: '/profile',
    admin: '/admin'
  },
  replacementPart: {
    base: '/replacement-part',
    log: '/replacementPart'
  },
  reservation: {
    base: '/reserves',
  },
  requestLimit: 20,
  reward: {
    base: '/reward',
    log: '/reward'
  },
  sanction: {
    base: '/sanction'
  },
  setting: {
    base: '/setting'
  },
  shortRequestLimit: 10,
  spotType: {
    base: '/spot-type',
    log: '/spotType'
  },
  statistic: {
    base: '/statistic',
    environmentalImpact: '/environmental-impact',
    hubs: '/hubs',
    mediums: '/mediums',
    sanctions: '/sanctions',
    subscriptions: '/subscriptions',
    trips: '/trips',
    users: '/users',
    workshop: '/workshop',
    replacementPart: '/replacement-part',
  },
  status: {
    base: '/status'
  },
  subscription: {
    base: '/subscription'
  },
  ticket: {
    base: '/ticket',
    reply: '/reply',
    comment: '/comment',
    unpaid: '/unpaid'
  },
  ticketType: {
    base: '/ticket-type'
  },
  trip: {
    base: '/trip',
    adminFinish: '/adminfinish',
    heatmap: '/heatmap'
  },
  user: {
    base: '/user',
    validDocument: '/validdocumentation',
    validEmail: '/validemail',
    status: '/status'
  },
  userSubscription: {
    base: '/user-subscription'
  },
  workshop: {
    base: '/workshop',
    log: '/workshopOrder'
  },
  workshopReason: {
    base: '/workshop-reason',
    log: '/workshopReason'
  },
};
