import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { EndpointsConstants } from '../../../helpers/endpoints';
import { BaseResponse } from 'src/app/models/base-response';
import { UploadedFile } from 'src/app/models/uploaded-file';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  url: string;
  constructor(private httpService: HttpService) {
    this.url = EndpointsConstants.file.base;
  }

  uploadFiles(file, typeClass, fileClass, showLoader = true): Observable<BaseResponse<UploadedFile>> {
    this.url = `${EndpointsConstants.file.base}${EndpointsConstants.file.file}`;
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append(typeClass, fileClass);

    return this.httpService.post(this.url, formData, showLoader);
  }
}
