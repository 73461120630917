import { BaseEntity } from './base-entity';
import { Medium } from './medium';
import { Hub } from './hub';
import { User } from './user';
import { CatalogReserveStatuses } from 'projects/admin/src/app/helpers/catalog';
import { LatestStatus } from './latest-status';

export class Reserve extends BaseEntity<Reserve> {
  id: number;
  createdAt: Date;
  cancelled: boolean;
  cancelledByAdmin: boolean;
  expired: boolean;
  used: boolean;
  status: string;
  idMedium: number;
  medium?: Medium;
  idHub: number;
  hub?: Hub;
  idUser: number;
  user?: User;
  fromTime: Date;
  toTime: Date;
  feedbackOption: string;
  feedbackExtraData: string;
  idStatus: number;
  latestStatus?: LatestStatus;

  constructor(reserve?) {
    super(reserve);
  }

  parse(e: any): Reserve {
    const reserve = new Reserve(e);
    return reserve;
  }

  populate(reserve?) {
    this.id = reserve.id;
    this.createdAt = new Date(reserve.createdAt);
    this.cancelled = reserve.cancelled;
    this.cancelledByAdmin = reserve.cancelledByAdmin;
    this.expired = reserve.expired;
    this.used = reserve.used;
    this.status = this.getStatus();
    this.idMedium = reserve.idMedium;
    if (reserve.medium) { this.medium = new Medium(reserve.medium); }
    this.idHub = reserve.idHub;
    if (reserve.hub) { this.hub = new Hub(reserve.hub); }
    this.idUser = reserve.idUser;
    if (reserve.user) { this.user = new User(reserve.user); }
    this.feedbackOption = reserve.feedbackOption;
    this.feedbackExtraData = reserve.feedbackExtraData;
    if (reserve.latestStatus) {
      this.idStatus = reserve.latestStatus.idStatus;
      this.latestStatus = new LatestStatus(reserve.latestStatus);
    }
  }

  getStatus() {
    if (!this.cancelled && !this.cancelledByAdmin && !this.expired && !this.used) {
      return CatalogReserveStatuses.active;
    }
    if (this.cancelled && !this.expired && !this.used) {
      return CatalogReserveStatuses.cancelled;
    }
    if (!this.expired && this.used) {
      return CatalogReserveStatuses.used;
    }
    if (this.expired) {
      return CatalogReserveStatuses.expired;
    }
    if (this.cancelledByAdmin) {
      return CatalogReserveStatuses.cancelledByAdmin;
    }
  }
}
