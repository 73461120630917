export enum CatalogApiScopes {
  admin = 'admin',
  hubSpots = 'hubSpots',
  status = 'status',
  statuses = 'statuses',
  statusType = 'statusType',
  lock = 'lock',
  latestPosition = 'latestPosition',
  positions = 'positions',
  mediumType = 'mediumType',
  latestTrip = 'latestTrip',
  hub = 'hub',
  medium = 'medium',
  mediums = 'mediums',
  user = 'user',
  currentTrip = 'currentTrip',
  trip = 'trip',
  trips = 'trips',
  rewardSubscription = 'rewardSubscription',
  subscription = 'subscription',
  currentSubscription = 'currentSubscription',
  payment = 'payment',
  penalty = 'penalty',
  additionalCharge = 'additionalCharge',
  additionalUse = 'additionalUse',
  stakeholders = 'stakeholders',
  ticketType = 'ticketType',
  assets = 'assets',
  comments = 'comments',
  failures = 'failures',
  extraData = 'extraData',
  latestHeartbeat = 'latestHeartbeat',
  responsables = 'responsables',
  reasons = 'reasons',
  replacementParts = 'replacementParts',
  all = 'all'
}

export enum CatalogDashboardUser {
  totalUsers = 'Usuarios totales',
  totalActiveUsers = 'Usuarios activos',
  totalInactiveUsers = 'Usuarios inactivos',
  totalSubscriptionUsers = 'Usuarios con abono'
}

export enum CatalogDashboardMedium {
  active = 'Activas',
  inactive = 'Inactivas',
  onhub = 'Ancladas',
  ongoing = 'En viaje',
  failure = 'Con fallas',
  total = 'total'
}

export enum CatalogHubStatuses {
  enabled = 7,
  disabled = 9
}

export enum CatalogTripStatuses {
  enabled = 7,
  disabled = 9
}

export const CatalogDashboardTrips = {
  ongoing: {
    label: 'Viajes en curso',
    id: 3
  },
  finished: {
    label: 'Viajes finalizados',
    id: 5
  },
  cancelled: {
    label: 'Viajes cancelados',
    id: 2
  },
  total: {
    label: 'total',
    id: 5
  }
};

export const CatalogDashboardReservations = {
  'reservesActive.total': {
    label: 'RESERVAS ACTIVAS TOTAL'
  },
  'reservesActive.mediumPercentage': {
    label: '% RESERVAS ACTIVAS'
  },
  'reservesUnused.cancelled': {
    label: 'RESERVAS CANCELADAS'
  },
  'reservesUnused.expired': {
    label: 'RESERVAS VENCIDAS'
  },
  'reservesFinished.total': {
    label: 'RESERVAS UTILIZADAS'
  },
  'reservesDaily.total': {
    label: 'TOTAL DIARIO DE RESERVAS'
  },
  'reservesDaily.users': {
    label: 'TOTAL DIARIO DE USUARIOS'
  },
  'trips.tripPercentage': {
    label: 'TOTAL DE VIAJES CON RESERVA'
  }
};

export const CatalogDashboardReservationsFeatured = {
  trip: 'trips.tripPercentage'
};

export const CatalogDashboardCards = {
  'user.active': {
    label: 'Usuarios activos',
    link: '/user/condition/active'
  },
  'user.inactive': {
    label: 'Usuarios inactivos',
    link: '/user/condition/inactive'
  },
  'medium.active': {
    label: 'Unidades activas',
    link: '/medium/dashboard/condition/active'
  },
  'medium.inactive': {
    label: 'Unidades inactivas',
    link: '/medium/dashboard/condition/inactive'
  },
  'hub.active': {
    label: 'Estaciones activas',
    link: `/hub/dashboard/status/${CatalogHubStatuses.enabled}`
  },
  'hub.inactive': {
    label: 'Estaciones inactivas',
    link: `/hub/dashboard/status/${CatalogHubStatuses.disabled}`
  },
  'trip.ongoing': {
    label: 'Viajes en curso',
    link: '/trip/dashboard/',
    idStatus: CatalogDashboardTrips.ongoing.id
  },
  'trip.finished': {
    label: 'Viajes finalizados',
    link: '/trip/dashboard',
    idStatus: CatalogDashboardTrips.finished.id
  },
  'trip.cancelled': {
    label: 'Viajes cancelados',
    link: '/trip/dashboard',
    idStatus: CatalogDashboardTrips.cancelled.id
  },
  'trip.total': {
    label: 'Total de viajes finalizados',
    link: '/trip/dashboard'
  },
  active: {
    label: 'Reservas activas',
    link: '/trip/dashboard'
  },
  cancelled: {
    label: 'Reservas canceladas',
    link: '/trip/dashboard'
  },
  expired: {
    label: 'Reservas expiradas',
    link: '/trip/dashboard'
  },
  used: {
    label: 'Reservas usadas',
    link: '/trip/dashboard'
  },
  daily: {
    label: 'Reservas diarias',
    link: '/trip/dashboard'
  },
  totalPercentage: {
    label: 'Porcentaje total de reservas versus viajes',
    link: '/trip/dashboard'
  },
};

export const CatalogMediumType = {
  adapted: 'ADAPTED_BIKE',
  cargo: 'CARGO_BIKE',
  bike: 'BIKE',
  cargoBikeFront: 'CARGO_BIKE_FRONT',
  cargoBikeMiddle: 'CARGO_BIKE_MIDDLE',
  cargoBikeBack: 'CARGO_BIKE_BACK',
  electricScooter: 'ELECTRIC_SCOOTER',
  electricBike: 'ELECTRIC_BIKE'
};

export const CatalogMediumTypeImgSources = [
  {
    code: CatalogMediumType.bike,
    image: 'assets/img/mediums/standar-default.png'
  },
  {
    code: CatalogMediumType.cargo,
    image: 'assets/img/mediums/cargo-default.png'
  },
  {
    code: CatalogMediumType.adapted,
    image: 'assets/img/mediums/adapted-default.png'
  },
  {
    code: CatalogMediumType.cargoBikeFront,
    image: 'assets/img/mediums/cargo_bike_front.png'
  },
  {
    code: CatalogMediumType.cargoBikeMiddle,
    image: 'assets/img/mediums/cargo_bike_middle.png'
  },
  {
    code: CatalogMediumType.cargoBikeBack,
    image: 'assets/img/mediums/cargo_bike_back.png'
  },
  {
    code: CatalogMediumType.electricScooter,
    image: 'assets/img/mediums/standar-default.png'
  },
  {
    code: CatalogMediumType.electricBike,
    image: 'assets/img/mediums/standar-default.png'
  },
];

export enum CatalogDashboardFeatured {
  admin = 'trip.total'
}

export enum CatalogDashboardUserDifferential {
  total = 'Totales',
  active = 'Activos',
  blocked = 'Bloqueados',
  inactive = 'Inactivos',
  sanction = 'Sancionados',
  subscription = 'Abonados'
}

export enum CatalogDashboardHub {
  full = 'Llena',
  empty = 'Vacía',
  onequarter = 'Por vaciarse',
  twoquarter = 'Ok',
  threequarter = 'Por llenarse',
  fourquarter = 'Llena',
  active = 'active',
  inactive = 'inactive'
}

export let CatalogHubIncident = {
  0: 'Vacío',
  0.25: 'Por vaciarse',
  0.5: 'Ok',
  0.75: 'Por llenarse',
  1: 'Lleno'
};

export enum CatalogMarkerIcon {
  HUB = './assets/img/map/hub_ico.svg',
  CARGO_BIKE = './assets/img/map/cargo_bike_ico.svg',
  CARGO_BIKE_BACK = './assets/img/map/cargo_bike_ico.svg',
  CARGO_BIKE_MIDDLE = './assets/img/map/cargo_bike_ico.svg',
  CARGO_BIKE_FRONT = './assets/img/map/cargo_bike_ico.svg',
  ADAPTED_BIKE = './assets/img/map/adapted_bike_ico.svg',
  ELECTRIC_SCOOTER = './assets/img/map/scooter_ico.svg',
  BIKE = './assets/img/map/bike_ico.svg',
  ELECTRIC_BIKE = './assets/img/map/scooter_ico.svg',
  LOCK = './assets/img/map/lock_ico.svg',
  USER = './assets/img/map/user_ico.svg',
  TRANSPARENT = './assets/img/map/transparent_ico.png'
}

export enum CatalogMediumImage {
  CARGO_BIKE = './assets/img/medium-types/cargo_bike_front.png',
  CARGO_BIKE_BACK = './assets/img/medium-types/cargo_bike_back.png',
  CARGO_BIKE_MIDDLE = './assets/img/medium-types/cargo_bike_middle.png',
  CARGO_BIKE_FRONT = './assets/img/medium-types/cargo_bike_front.png',
  DEFAULT = './assets/img/medium-types/cargo_bike_front.png',
}

export enum CatalogIcon {
  hub = './assets/img/icons/hub.png',
}

export enum CatalogApiTerms {
  medium = 'medium',
  hub = 'hub',
  lock = 'lock',
  user = 'user',
  trip = 'trip',
  additionalUse = 'additional_use',
  additionalCharge = 'additional_charge',
  sanction = 'sanction',
  workshop = 'workshop'
}

export enum CatalogResponsiveBreakpoints {
  desktop = 1200,
  laptop = 991,
  tablet = 768,
  smart = 480
}

export const CatalogMediumStatuses = {
  paused: 'PAUSED',
  ongoing: 'ONGOING',
  cancelled: 'CANCELLED',
  finalizedManually: 'FINALIZED_MANUALLY',
  finalized: 'FINALIZED',
  notStarted: 'NOT_STARTED',
  reserved: 'RESERVED',
  inTrip: 'IN_TRIP',
  inHub: 'IN_HUB',
  inStreet: 'IN_STREET',
  blockedForSwinging: 'BLOCKED_FOR_SWINGING',
  blockedForMaintenance: 'BLOCKED_FOR_MAINTENANCE',
  blockedForWorkshop: 'BLOCKED_FOR_WORKSHOP',
  releasedForWorkshop: 'RELEASED_FOR_WORKSHOP',
  reserveCancelled: 'RESERVE_CANCELLED',
  swingingByOperator: 'SWINGING_BY_OPERATOR',
  swingingByUser: 'SWINGING_BY_USER',
  inMaintenance: 'IN_MAINTENANCE',
  inWorkshop: 'IN_WORKSHOP',
  inHeadquarters: 'IN_HEADQUARTERS',
  obsolete: 'OBSOLETE',
  missing: 'MISSING',
  inconsistent: 'INCONSISTENT',
};

export const CatalogStatusesColors = [
  {
    color: 'gray',
    statuses: [
      CatalogMediumStatuses.inMaintenance,
      CatalogMediumStatuses.inWorkshop,
      CatalogMediumStatuses.blockedForSwinging,
      CatalogMediumStatuses.blockedForMaintenance,
      CatalogMediumStatuses.blockedForWorkshop,
      CatalogMediumStatuses.releasedForWorkshop,
      CatalogMediumStatuses.swingingByOperator,
      CatalogMediumStatuses.swingingByUser,
      CatalogMediumStatuses.inMaintenance
    ]
  },
  {
    color: 'blue',
    statuses: [
      CatalogMediumStatuses.inHub,
      CatalogMediumStatuses.inStreet
    ]
  },
  {
    color: 'green',
    statuses: [
      CatalogMediumStatuses.inTrip,
      CatalogMediumStatuses.ongoing
    ]
  },
  {
    color: 'orange',
    statuses: [
      CatalogMediumStatuses.reserved
    ]
  },
  {
    color: 'red',
    statuses: [
      CatalogMediumStatuses.obsolete,
      CatalogMediumStatuses.missing,
      CatalogMediumStatuses.inconsistent,
      CatalogMediumStatuses.inHeadquarters
    ]
  }
];

export const CatalogUserType = {
  real: 'Real',
  test: 'Prueba',
};

export enum CatalogMediumBlockStatuses {
  blockedSwinging = 14
}

export enum CatalogMediumUnlockStatuses {
  releasedWorkshop = 17,
  swingingOperator = 18
}

export enum CatalogMediumRepairStatuses {
  blockedMaintenance = 15
}

export const CatalogIdStatuses = {
  inHub: 12,
  changeStatusExceptions: [12, 14, 17, 18, 21]
};

export const CatalogIdStatusesShowButton = {
  unblock: [14, 18],
  block: [10, 12, 13, 25],
  repair: [10, 12, 13, 25],
  removeRepair: [15, 16],
  workshop: [15, 17]
};

export enum CatalogPenaltyType {
  'HIGH' = 'Grave',
  'MEDIUM' = 'Moderada',
  'LOW' = 'Leve',
  'APPLIED' = 'Aplicada'
}

export enum CatalogTicketTypePriority {
  'HIGH' = 'Alta',
  'MEDIUM' = 'Media',
  'LOW' = 'Baja'
}

export enum CatalogAdditionalUseIdStatuses {
  pendingReview = 31,
  rejected = 32,
  toCharge = 33,
  toSanction = 34,
  toChargeSanction = 35
}

export enum CatalogAdditionalUseStatuses {
  pendingReview = 'ADDITIONAL_PENDING_REVIEW',
  rejected = 'ADDITIONAL_REJECTED',
  toCharge = 'ADDITIONAL_TO_CHARGE',
  toSanction = 'ADDITIONAL_TO_SANCTION',
  toChargeSanction = 'ADDITIONAL_TO_CHARGE_SANCTION'
}

export enum CatalogWorkshopStatuses {
  waitingForRepair = 'WAITING_FOR_REPAIR',
  waitingForReplacementParts = 'WAITING_FOR_REPLACEMENT_PARTS',
  inRepair = 'IN_REPAIR',
  repaired = 'REPAIRED',
  obsolete = 'OBSOLETE'
}

export const CatalogAdditionalUseEndpoints = [
  {
    key: 'sanction',
    label: 'Generar sanción',
    hasSanction: true,
    hasCharge: false
  },
  {
    key: 'additionalCharge',
    label: 'Generar cobro adicional',
    hasSanction: false,
    hasCharge: true
  },
  {
    key: 'sanctionAndAdditionalCharge',
    label: 'Generar sanción y cobro adicional',
    hasSanction: true,
    hasCharge: true
  }
];

export enum CatalogAdditionalChargeIdStatuses {
  rejected = 38,
  review = 36
}

export enum CatalogAdditionalChargeStatuses {
  rejected = 'CHARGE_REJECTED',
  review = 'CHARGE_IN_REVIEW',
  pending = 'CHARGE_PENDING_PAYMENT',
  paid = 'CHARGE_PAID'
}

export enum CatalogUserStatuses {
  pendingDocumentationForReview = 29
}

export enum CatalogSanctionIdStatuses {
  rejected = 50,
  applied = 51,
  inReview = 52,
  paid = 59
}

export enum CatalogSanctionStatuses {
  rejected = 'SANCTION_REJECTED',
  applied = 'SANCTION_APPLIED',
  inReview = 'SANCTION_IN_REVIEW',
  paid = 'SANCTION_PAID'
}

export enum CatalogCodeSpotType {
  'ELECTRIC_BIKE' = 'ELECTRIC_BIKE',
  'ADAPTED_BIKE' = 'ADAPTED_BIKE',
  'CARGO_BIKE' = 'CARGO_BIKE',
  'BIKE' = 'BIKE',
  'ELECTRIC_SCOOTER' = 'ELECTRIC_SCOOTER'
}

export enum CatalogSettings {
  'generalVtl' = 'GENERAL_VTL',
  'qrCode' = 'QR',
  'timezone' = 'TIMEZONE'
}

export enum CatalogFieldTypes {
  'checkbox' = 'CHECKBOX',
  'checkboxMultiple' = 'CHECKBOXMULTIPLE',
  'input' = 'INPUT',
  'textarea' = 'TEXTAREA',
  'select' = 'SELECT'
}

export enum CatalogImageClass {
  'base' = 'imageClass',
  'avatar' = 'avatar',
  'ticketAsset' = 'ticketAsset',
  'replacementPart' = 'replacementPart'
}

export enum CatalogFileClass {
  'base' = 'pdfClass',
  'documentation' = 'documentation',
  'csv' = 'csv',
  'documentClass' = 'documentClass',
  'pdf' = 'pdf'
}

export const CatalogMimeTypes = {
  csv: 'text/csv',
  pdf: 'application/pdf'
};

export enum CatalogAuthorizationStatus {
  authorized = 'authorized'
}

export enum CatalogAuthorizationDetail {
  pendingCapture = 'pending_capture'
}

export enum CatalogPaymentStatuses {
  paymentcreate = 'paymentcreate',
  pending = 'pending',
  approved = 'approved',
  authorized = 'authorized',
  in_process = 'in_process',
  in_mediation = 'in_mediation',
  rejected = 'rejected',
  cancelled = 'cancelled',
  refunded = 'refunded',
  charged_back = 'charged_back',
  preferencecreate = 'preferencecreate'
}

export enum CatalogConditionUser {
  active = 'Activo',
  inactive = 'Inactivo'
}

export enum CatalogConditionMedium {
  active = 'Activa',
  inactive = 'Inactiva'
}

export enum CatalogProfiles {
  superAdmin = 'super_admin',
  operador = 'operador'
}

export enum CatalogProfilesLabel {
  super_admin = 'Super Admin',
  operador = 'Operador'
}

export enum CatalogSeverity {
  notification = 1,
  alert = 2
}

export enum CatalogAlertTypes {
  medium = 'medium'
}

export const DefaultAdminAvatar = './assets/img/dashboard-SMOD/default-user.png';

export enum CatalogSocketType {
  pushAdmin = 'push_admin',
  newCommentTicket = 'new_comment_ticket'
}

export const CatalogLogFieldHasHTMLContent = ['Contenido del email', 'Comentario'];

export const CatalogDays = [
  {
    name: 'Lunes',
    id: 'Monday'
  },
  {
    name: 'Martes',
    id: 'Tuesday'
  },
  {
    name: 'Miércoles',
    id: 'Wednesday'
  },
  {
    name: 'Jueves',
    id: 'Thursday'
  },
  {
    name: 'Viernes',
    id: 'Friday'
  },
  {
    name: 'Sábado',
    id: 'Saturday'
  },
  {
    name: 'Domingo',
    id: 'Sunday'
  }
];

export const CatalogStatisticsUserPeriod = [
  {
    id: 'days',
    label: 'Diario'
  },
  {
    id: 'weeks',
    label: 'Semanal'
  },
  {
    id: 'months',
    label: 'Mensual'
  },
];

export const CatalogStatisticsUserPeriodSelected = {
  days: 'Usuarios por día',
  weeks: 'Usuarios por semana',
  months: 'Usuarios por mes',
};

export const CatalogRewardUserCodes = [
  {
    key: 'tripQuantity',
    value: 'Cantidad de viajes'
  },
  {
    key: 'kilometers',
    value: 'Kilómetros'
  },
  {
    key: 'calories',
    value: 'Calorías'
  },
  {
    key: 'co2Avoided',
    value: 'CO2 Evitado'
  },
  {
    key: 'plantedTrees',
    value: 'Árboles plantados'
  },
];

export const CatalogCommunicationRecipientsTypes = {
  activeUserTrips: 'Usuarios Activos',
  inactiveUserTrips: 'Usuarios Inactivos',
  validatedDocumentation: 'Con documentación completa',
  notValidatedDocumentation: 'Con documentación pendiente',
  foreign: 'Turistas',
  notForeign: 'No turistas',
  allUsers: 'Todos los usuarios',
};

export const CatalogReserveStatuses = {
  active: 'Activa',
  cancelled: 'Cancelada',
  used: 'Utilizada',
  expired: 'Vencida',
  cancelledByAdmin: 'Cancelada por el admin'
};

export const CatalogReserveStatusesOptions = [
  {
    name: CatalogReserveStatuses.active,
    id: 'active',
  },
  {
    name: CatalogReserveStatuses.cancelled,
    id: 'cancelled',
  },
  {
    name: CatalogReserveStatuses.used,
    id: 'used',
  },
  {
    name: CatalogReserveStatuses.expired,
    id: 'expired',
  },
  {
    name: CatalogReserveStatuses.cancelledByAdmin,
    id: 'cancelledByAdmin',
  },
];

export const CatalogReserveBadgeColorByStatus = {
  [CatalogReserveStatuses.expired]: 'badge-danger',
  [CatalogReserveStatuses.active]: 'badge-success',
  [CatalogReserveStatuses.cancelled]: 'badge-warning',
  [CatalogReserveStatuses.used]: 'badge-info',
  [CatalogReserveStatuses.cancelledByAdmin]: 'badge-dark',
};

export const CatalogCommunicationRecipientsTypesOptions = [
  {
    key: 'Usuarios activos',
    value: 'activeUserTrips',
  },
  {
    key: 'Usuarios inactivos',
    value: 'inactiveUserTrips',
  },
  {
    key: 'Con documentación completa',
    value: 'validatedDocumentation',
  },
  {
    key: 'Con documentación pendiente',
    value: 'notValidatedDocumentation',
  },
  {
    key: 'Turistas',
    value: 'foreign',
  },
  {
    key: 'No Turistas',
    value: 'notForeign',
  },
];

export const CatalogHubPriorityOptions = [
  {
    name: 'Alta',
    id: 'HIGH',
  },
  {
    name: 'Media',
    id: 'MEDIUM',
  },
  {
    name: 'Baja',
    id: 'LOW',
  },
];

export const CatalogHubPriorityLabel = {
  HIGH: 'Alta',
  MEDIUM: 'Media',
  LOW: 'Baja',
};

export const CatalogCommunicationType = {
  push: 'push',
};

export const CatalogAssetTypesCodes = {
  img: 'IMG',
  imgFile: 'IMG_FILE',
  pdf: 'PDF',
  pdfFile: 'PDF_FILE',
  csv: 'CSV'
};
